<template>
  <Breadcrumbs title="" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header" v-if="this.eventDetails.title">
            <div class="d-flex justify-content-center">
              <figure class="col-xl-12 col-sm-12">
                <img style="width: 100%;box-shadow: 0em 0em 1em rgb(0 0 0 / 50%);border-bottom-left-radius: 0.6em;border-bottom-right-radius: 0.6em;border: 0px;"
                  :src="this.eventDetails.coverurl"
                  alt="Image description"
                  class="img-thumbnail"
                />
              </figure>
            </div>
            <h3>{{ this.eventDetails.title }}</h3>
           
              <b><i class="fa fa-calendar-check-o" aria-hidden="true"></i> {{ this.eventDetails.event_date_text }} </b>
              <br />
              <b>&nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp; {{ this.eventDetails.province }} </b>
              <br />
              <b>{{this.currentLanguage ==='en'?"Open search at":"เปิดค้นหา"}}: {{ this.eventDetails.on_sale_start }}</b>
              <br />
              <p v-if="this.eventDetails.day_diff<=10 && this.eventDetails.day_diff >0">{{this.currentLanguage ==='en'?"To":"ถึงวันที่"}}: {{ this.eventDetails.on_sale_end }} <font style="color: red"> ({{this.currentLanguage ==='en'?"There is still time left to store photo":"คงเหลือเวลาจัดเก็บรูปอีก"}} {{ this.eventDetails.day_diff }} {{this.currentLanguage ==='en'?"days":"วัน"}})</font> </p> 
              <p v-else > {{this.currentLanguage ==='en'?"To":"ถึงวันที่"}}: {{ this.eventDetails.on_sale_end }}</p>
            
            <p>
              <span style="padding: 3px;margin: 3px;"
                v-for="(_package, index) in this.eventPackage"
                :key="index"
                class="btn btn-outline-info"><i class="fa fa-tag fa-sm" aria-hidden="true"></i><b>{{this.currentLanguage ==='en'?_package.name_en:_package.name}}</b>
              </span>
            </p>
            <!-- hb event -->
            <a :href="this.$route.params.event_id+'/wholesale'">
            <button v-if="this.eventDetails.is_hybrid === 'Y'" class="btn btn-lg btn-primary" style="width: 100%" >{{this.currentLanguage ==='en'?"Get unlimited package click":"ซื้อโปรเหมาๆ ราคาพิเศษ คลิ๊กที่นี่"}}</button></a>
            <!-- hb event -->
            <p></p>
            <div class="row">
              <!-- <div class="col-sm-1"></div> -->
              <div class="col-sm-12" v-if="this.eventDetails.can_sale === 'Y'">
                <div v-if="this.eventDetails.close_sale === 'N'">
                <label
                  for="fileInput"
                  class="drop-container"
                  id="dropcontainer"
                  @change="faceSearch"
                  
                >
                  <span class="drop-title" v-if="isSearching !== 'true'"
                    ><vue-feather
                      type="image" size="36"
                      class="text-top"
                    ></vue-feather><br>
                    {{this.currentLanguage ==='en'?"upload you face for find images":"อัปโหลดภาพใบหน้าเพื่อค้นหา..."}}</span
                  >
                  
                  <span class="drop-title" v-if="isSearching === 'true'"
                    ><img style="align-items: center; width: 30px"
                        src="../../assets/images/running_loading.svg"
                      />
                    <!-- <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i> -->
                    {{this.currentLanguage ==='en'?"Searching...":"กำลังค้นหา..."}}</span
                  >
                  <input
                    class="btn btn-square btn-outline-info btn-lg"
                    type="file"
                    id="fileInput"
                    accept="image/png, image/jpeg"
                    required
                  />
                </label>
              </div>
              <div  v-else>
                <div class="d-flex justify-content-center">
                  <vue-feather type="clock" size="36"></vue-feather>
                </div>
                <div class="d-flex justify-content-center">
                  {{this.currentLanguage ==='en'?"End of Sale":"หมดเวลาเก็บข้อมูลกิจกรรม"}}
                </div>
                
                
              </div>
              </div>
              <div class="col-sm-12" v-if="this.eventDetails.can_sale === 'N'">
                <p>&nbsp;</p>
                <div class="d-flex justify-content-center">
                  
                  <h4>
                    {{this.currentLanguage ==='en'?"Open at":"เปิดให้ค้นหา"}} {{ this.eventDetails.on_sale_start }}
                  </h4>
                </div>
                <!-- <div class="d-flex justify-content-center">
                  <h6>อดใจรอสักครู่...</h6>
                </div> -->
                <div class="d-flex justify-content-center">
                  <vue-feather
                    type="activity"
                    size="36"
                    animation-speed="slow"
                  ></vue-feather>
                </div>
              </div>
              <!-- <div class="col-sm-1"></div> -->
            </div>
          </div>

          <div class="card-header" v-if="!this.eventDetails.title">
            <div class="d-flex justify-content-center" >
                <img style="align-items: center; width: 60px"
                        src="../../assets/images/running_loading.svg"
                      />
            </div>
            <div class="d-flex justify-content-center">
              {{this.currentLanguage ==='en'?"loading...":"กำลังโหลดข้อมูลกิจกรรม..."}}
            </div>
          </div>
        </div>
        <div class="card" v-if="this.is_show_image_search === 'Y'">
          <div v-if="cart_store.cart_images.length > 0">
            <p></p>
            <button
              style="width: 100%"
              v-if="
                this.eventDetails.is_free_download === 'Y' &&
                this.images_download_all.length > 0
              "
              type="button"
              class="btn btn-primary"
              @click.prevent="load_images()"
            >
            {{this.currentLanguage ==='en'?"Download all photos":"ดาวน์โหลดรูปทั้งหมด"}}
            </button>
            <p style="text-align: center">
              {{this.currentLanguage ==='en'?"Found matching face ":"ค้นพบภาพของท่านจำนวน "}}<b> {{ cart_store.cart_images.length }}</b> {{this.currentLanguage ==='en'?"items":"ใบ"}}
            </p>


            
            <button  v-if=" cart_store.cart_images.length > 0 && this.eventDetails.is_free_download_keep_contract === 'Y' " class="btn btn-lg btn-primary"
              @click="
                cart_store.addToCartALLImage(
                  cart_store.cart_images,
                  'N',
                  0
                ) " style="width: 100%" >
              +<vue-feather type="shopping-cart" stroke-width="2"></vue-feather> {{this.currentLanguage ==='en'?"Add all to cart":"เลือกรูปทั้งหมดใส่ตะกร้า"}}
            </button>

            <button  v-if=" cart_store.cart_images.length > 0 && this.eventDetails.is_mhao === 'Y' " class="btn btn-lg btn-primary"
              @click="
                cart_store.addToCartALLImage(
                  cart_store.cart_images,
                  this.eventDetails.is_mhao,
                  this.eventDetails.mhao_price
                ) " style="width: 100%" >
              +<vue-feather type="shopping-cart" stroke-width="2"></vue-feather> {{ this.eventDetails.caption_button_buy_all }}
              ( {{this.currentLanguage ==='en'?"Avg":"เฉลี่ย"}} {{ this.imgs_avg_price }} ) {{this.currentLanguage ==='en'?"per items":"ต่อใบ"}}
            </button>
            <p></p>
            <div v-if=" cart_store.cart_images.length > 0 && this.eventDetails.is_mhao === 'Y' " class="d-flex justify-content-center" >
              
              {{this.currentLanguage ==='en'?"Note* buy all items per one face searched. or one time searching In the event that you have already purchased additional photos, you can notify the admin so that additional photos can be sent immediately.":"หมายเหตุ* การเหมา เป็นการเหมาต่อหนึ่งใบหน้าที่ค้นหา หรือหนึ่งครั้งที่ค้นหา กรณีที่เหมาไปแล้ว เจอภาพอื่นๆ เพิ่มเติม สามารถแจ้งแอดมินเพื่อส่งรูปเพิ่มเติมให้ได้ทันที"}}
            </div>

            <div id="imageShowing">
              <figure
                v-for="(src, index) in cart_store.cart_images"
                :key="index"
                @click="() => showImg(index)"
              >
                <div class="gallery event">
                  <img
                    loading="lazy"
                    :src="src.thumbnail_file_name"
                    :alt="src.original_file_name"
                  />

                  <button style="font-size: small;"
                    v-if="
                      src.atc == 'N' &&
                      this.eventDetails.is_free_download === 'Y'
                    "
                    class="btn-free-download"
                    @click="
                      this.download(
                        src.preview_file_name + '?openExternalBrowser=1'
                      ),
                        addToCart()
                    "
                  >
                  Download
                  </button>

                  <button
                    v-if="src.atc == 'N' &&
                      this.eventDetails.is_free_download !== 'Y'
                    "
                    class="btn" style="font-size: small;"
                    @click="
                      cart_store.add_cart(
                        src.id,
                        src.original_file_name,
                        src.thumbnail_file_name,
                        src.preview_file_name,
                        src.photographer_id,
                        src.event_id,
                        this.currentLanguage
                      ),
                        addToCart()
                    "
                  >
                    +{{this.currentLanguage ==='en'?"Add":"เพิ่ม"}}
                  </button>

                  <button
                    v-if="
                      src.atc == 'Y' &&
                      this.eventDetails.is_free_download !== 'Y'
                    "
                    class="btn-delete" style="font-size: small;"
                    @click="
                      cart_store.delete_cart(
                        src.id,
                        src.original_file_name,
                        src.thumbnail_file_name,
                        src.photographer_id
                      ),
                        addToCart()
                    "
                  >
                    {{this.currentLanguage ==='en'?"remove":"-ลบ"}}
                  </button>
                </div>
              </figure>
            </div>
          </div>
          <div
            v-if="this.isFoundImage !== 'true'"
            class="d-flex justify-content-center"
          >
            <div class="card-header">
              <h5>{{this.currentLanguage ==='en'?"Can't find the picture, try changing the picture used to search. Or choose a picture that has only one face in it and is clear, and try again.":"ไม่พบรูป, ลองเปลี่ยนรูปที่ใช้ในการค้นหา หรือเลือกรูปที่มีใบหน้าเพียงคนเดียวในรูป และชัดเจน  แล้วลองอีกครั้ง"}}</h5>
              <p>{{this.currentLanguage ==='en'?"Can't find it? Try asking the admin to help you find it. You can send pictures to":"หาไม่เจอ ลองให้แอดมินช่วยหามั๋ย ส่งรูปมาได้เลยที่"}} <a href="https://lin.ee/CBuSJCz" target="_blank"> @running.in.th</a></p>
            </div>
          </div>
          <vue-easy-lightbox
            pinchDisabled="true"
            scrollDisabled="true"
            :zoomDisabled="this.is_show_image_search==='Y'?true:false"
            loop="true"
            rotateDisabled="true"
            moveDisabled="true"
            :visible="visible"
            :index="index"
            :imgs="this.is_show_image_search==='Y'?lightBoxImages:lightBoxImages_hl"
            @hide="handleHide"
          >
            <!-- <template v-slot:close-btn="{  }">
           
          </template> -->

            <template v-slot:toolbar="{}">
              <!-- <button class="close-btn" @click="this.addToCartlb(index)">add+</button>
              <button @click="toolbarMethods.zoomIn">zoom in</button>
            <button @click="toolbarMethods.zoomOut">zoom out</button>
            <button @click="toolbarMethods.rotateLeft">Anticlockwise rotation</button>
            <button @click="toolbarMethods.rotateRight">clockwise rotation</button> -->
            </template>
          </vue-easy-lightbox>
        </div>
        <div class="card" v-if="this.is_show_image_search !== 'Y' && this.data_highlight.length>0">
          <p>&nbsp;</p>
          <h3 style="text-align: center"> <vue-feather type="bar-chart" size="20"></vue-feather> {{this.currentLanguage ==='en'?"Event highlight":"Highlight ภาพกิจกรรม"}}  </h3>
          <div v-if="this.loadding_highlight !== 'Y'"  >
            <div
              v-for="(src, index_hl) in this.data_highlight"
              :key="index_hl"
              @click="() => showImg(index_hl)"
            >
              <div class="gallery_hl event_hl">
                <img
                  loading="lazy"
                  :src="src.thumbnail_file_name"
                  :alt="src.original_file_name"
                />
              </div>
            </div>
          </div>
          <div v-if="this.loadding_highlight === 'Y'"  >
              <div class="d-flex justify-content-center" >
                <img style="align-items: center; width: 60px"
                        src="../../assets/images/running_loading.svg"
                      />
              </div>
          </div>
          <p>&nbsp;</p>
          <vue-easy-lightbox
            pinchDisabled="true"
            scrollDisabled="true"
            :zoomDisabled="this.is_show_image_search==='Y'?true:false"
            loop="true"
            rotateDisabled="true"
            moveDisabled="true"
            :visible="visible"
            :index="index"
            :imgs="this.is_show_image_search==='Y'?lightBoxImages:lightBoxImages_hl"
            @hide="handleHide"
          >
            <!-- <template v-slot:close-btn="{  }">
           
          </template> -->

            <template v-slot:toolbar="{}">
              <!-- <button @click="toolbarMethods.zoomIn">zoom in</button>
            <button @click="toolbarMethods.zoomOut">zoom out</button>
            <button @click="toolbarMethods.rotateLeft">Anticlockwise rotation</button>
            <button @click="toolbarMethods.rotateRight">clockwise rotation</button> -->
            </template>
          </vue-easy-lightbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

import "@sweetalert2/theme-bootstrap-4/bootstrap-4.scss";
import { ref } from "vue";
import router from "../../router";
import { useCartStore } from "../../store/modules/cart";
import axios from "axios";
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
const isBtnaddToCartClick = ref(0);
const isBtnshowImgClick = ref(0);

export default {
  setup() {
    const siteData = reactive({
      title: `ค้นหารูปความประทับใจด้วยใบหน้า | RUNNING.IN.TH`,
      description: `ค้นหารูปวิ่งสุดประทับใจด้วยใบหน้า ด้วยใบหน้า ค้นง่าย จ่ายสะดวก โหลดรูปต้นฉบับ ความละเอียดสูงได้ทันที`,
      keywords: `ค้นหารูปวิ่ง, หารูปวิ่ง,งานวิ่ง,running,runninghub,facesearch`,
      image:  `-`,
    })

    useHead({
      // Can be static or computed this.eventDetails.title
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords),
        },
        {
          property: `og:image`,
          content: computed(() => siteData.image),
        },
        ],
     
    })

    
    const cart_store = useCartStore();
    cart_store.loadFromLocalStorage();
    return { cart_store ,siteData};
  },

  data() {
    return {
      isSearching: "false",
      isFoundImage: "true",
      eventDetails: {},
      eventPackage: {},
      lightBoxImages: [],
      visible: false,
      index: "",
      cart: [],
      imgs_avg_price: 0,
      images_download_all: [],


      lightBoxImages_hl: [],
      index_hl: "",
      visible_hl: false,
      loadding_highlight: "",
      data_highlight: [],
      is_show_image_search: "" ,
      currentLanguage: 'th'

    
    };
  },
  async created(){
    this.currentLanguage = localStorage.getItem('currentLanguage');
  },
  async mounted() {
    this.highlight();
    let _data = await this.getEventDetail(this.$route.params.event_id);
    this.siteData.image = _data.coverurl ;
    this.siteData.title = _data.title +' '+this.siteData.title ;
    this.siteData.description = _data.title +' วันที่ '+_data.event_date_text+' ณ.'+_data.province+' '+this.siteData.description ;
    
    if (this.lightBoxImages.length <= 0) {
      this.lightBoxImages.length = [];
      this.images_download_all = [];
      
      if(this.cart_store.cart_images.length > 0 ){
        this.is_show_image_search = "Y"
        this.cart_store.cart_images.forEach((item) => {
        
        this.lightBoxImages.push({
          src: item.preview_file_name,
          title:
            "IMAGEID:" +
            this.$route.params.event_id +
            "" +
            item.photographer_id +
            "" +
            item.id,
        });
        this.images_download_all.push(item.preview_file_name);
      });
      this.imgs_avg_price  = (this.eventDetails.mhao_price/this.cart_store.cart_images.length).toFixed(2)
      }

    }

    //this.highlight();
  },
  methods: {
    async highlight() {
      this.loadding_highlight = "Y";
      let data = {
        event_key: this.$route.params.event_id,
      };
      const response = await axios.post("/v1/facesearch/highlight", data);

      if (response.data.status === "success") {
        this.data_highlight = response.data.body;

        this.data_highlight.forEach((item) => {
          this.lightBoxImages_hl.push({
            src: item.preview_file_name,
            title: 'ตัวอย่างภาพความประทับใจ',
          });
        
      });

        this.loadding_highlight = "N";
      } else {
        this.loadding_highlight = "N";
        //alert("Load order พบข้อผิดพลาด!");
      }
    },
    async sleep_only() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, 200);
      });
    },
    async load_images() {
      let result = await Swal.fire({
        icon: "info",
        title:
          "กรุณาเปิดอนุญาตป๊อปอัป เมื่อเริ่มดาวน์โหลด,  ระบบจะดาวน์โหลดรูปทั้งหมด อัตโนมัติ ",
        html: "<b><u>แนะนำใช้งานผ่านคอมพิวเตอร์หรือระบบแอนดรอยด์</u></b>,<br> บนระบบ ios ระบบจะเปิดแทบเท่ากับจำนวนรูปเพื่อให้กดค้างเพื่อบันทึก <br>คุณต้องการดาวน์โหลดรูปทั้งหมดใช่หรือไม่?",

        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#7991a8",
        confirmButtonText: "เริ่มดาวน์โหลด",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          let count = 0;
          // console.log("images_dl.length"+this.images_download_all.length)
          while (count < this.images_download_all.length) {
            window.open(
              this.images_download_all[count] + "?openExternalBrowser=1",
              "_blank"
            );
            this.sleep_only();
            count++;
          }
        } else {
          return false;
        }
      });
    },
    async download(url) {
      window.open(url, "_blank");
    },
    async getEventDetail(event_key) {
      const res = await axios.get("/v1/events/" + event_key);
      //console.log("res.data",JSON.stringify(res.data))
      if (res.data.status === "success") {
        this.eventDetails = res.data;
        this.eventPackage = res.data.package;

        if(this.eventDetails.is_mhao==='N'){
          localStorage.setItem('_is_mhao_flag','NO')
          this.cart_store.is_mhao_flag = 'NO'
          
        }

        let consent_check = localStorage.getItem(
          "consent_" + this.$route.params.event_id
        );
        if (consent_check !== "true") {
          if(this.currentLanguage === 'en'){
            let result = await this.cart_store.alert_policy_consent_en();
            if (result) {
              localStorage.setItem(
                "consent_" + this.$route.params.event_id,
                "true"
              );
            } else {
              router.push({ path: "/" });
            }
          }else{
            let result = await this.cart_store.alert_policy_consent();
            if (result) {
              localStorage.setItem(
                "consent_" + this.$route.params.event_id,
                "true"
              );
            } else {
              router.push({ path: "/" });
            }
          }
          

        }
        return res.data;
      } else {
        //alert("พบข้อผิดพลาด! ไม่พบ url ของกิจกรรม");
        router.push({ path: "/404" });
      }
    },
    addToCart() {
      isBtnaddToCartClick.value = 1;
    },
    addAllImageToCart() {
      //router.push({ path: "cart" });
    },
    showImg(index) {
      isBtnshowImgClick.value = 1;

      if (isBtnaddToCartClick.value === 0) {
        this.index = index;
        this.visible = true;
      }
      isBtnaddToCartClick.value = 0;
      isBtnshowImgClick.value = 0;
    },
    handleHide() {
      this.visible = false;
      isBtnaddToCartClick.value = 0;
      isBtnshowImgClick.value = 0;
    },
    faceSearch() {
      if(this.eventDetails.is_mhao === 'Y'){
        
        if(this.cart_store.cart.length > 0){
          if(confirm(this.currentLanguage==='en'?"You have photos in your shopping cart. If you search again The pictures in the basket will be Automatically deleted Do you want to continue the transaction?":"ท่านมีรูปที่ทำรายการค้างอยู่ในตะกร้า ถ้าหากค้นหาใหม่อีกครั้ง รูปที่อยู่ในตะกร้าจะถูก ลบออกโดยอัตโนมัติ  ต้องการทำรายการต่อใช่หรือไม่?")){
            this.cart_store.clear_cart(this.eventDetails.is_mhao)
            this.faceSearch_action();
          }else{
            document.getElementById("fileInput").value = null;
          }

        }else{ 
          this.faceSearch_action();
        }

      }else{
        this.faceSearch_action();
      }

        

      
    },
    faceSearch_action(){
      if (document.getElementById("fileInput").value != "") {
        var file_size = fileInput.files[0].size / 1000000;
        console.log("fileInput size mb:" + file_size);
        //console.log("fileInput size type:" + fileInput.files[0].type);//image/jpeg  image/png
        if(fileInput.files[0].type === 'image/jpeg' || fileInput.files[0].type === 'image/png'){
          this.lightBoxImages = [];
        this.isSearching = "true";
        this.is_show_image_search = "Y"
        this.isFoundImage = "true";
        this.cart_store.clear_cart_images();
        this.cart_store.clear_cart_latest();
        this.imgs_avg_price = 0;
        document.getElementById("fileInput").disabled = true;
        var formdata = new FormData();
        //var requestOptions = {
        //  method: "POST",
        //  body: formdata,
        //  redirect: "follow",
        //};
        formdata.append("file", fileInput.files[0], fileInput.files[0].name);
        formdata.append("event_id", this.eventDetails.event_id);
        formdata.append("event_key", this.$route.params.event_id);
        formdata.append("face_threshold", this.eventDetails.face_threshold);
        formdata.append("mhao_price", this.eventDetails.mhao_price);
        formdata.append("max_face_search", this.eventDetails.max_face_search);
        //console.log(this.eventDetails.mhao_price)
        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        fetch(
          process.env.VUE_APP_RUNNING_API + "/v1/facesearch",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            result = JSON.parse(result);

            //console.log("result.status " + JSON.stringify(result));
            if (result.status === "success") {
              if (result.image_length > 0) {
                this.isFoundImage = "true";
                this.cart_store.cart_images = result.images;

                if(this.eventDetails.is_mhao === 'Y'){
                  this.cart_store.cart_latest = result.images;
                }
                localStorage.setItem('cart_latest', JSON.stringify(this.cart_store.cart_latest))

                this.imgs_avg_price = result.avg_price;
                this.cart_store.cart_images.forEach((item) => {
                  //this.lightBoxImages.push(item.preview_file_name);
                  this.lightBoxImages.push({
                    src: item.preview_file_name,
                    title:
                      this.$route.params.event_id +
                      ":RN" +
                      item.photographer_id +
                      "-" +
                      "IMAGEID:" +
                      item.id,
                  });
                  this.images_download_all.push(item.preview_file_name);
                });
                this.isSearching = "false";
                document.getElementById("fileInput").disabled = false;
              } else {
                this.isSearching = "false";
                document.getElementById("fileInput").disabled = false;
                //alert("ไม่พบรรูปที่ตรงกัน");
                this.isFoundImage = "false";
              }
            } else {
              this.isSearching = "false";
              this.isFoundImage = "false";
              document.getElementById("fileInput").disabled = false;
              this.cart_store.alert_commomd_message_info(
                "พบข้อผิดพลาดจากการค้นหา กรุณาลองอีกครั้ง"
              );
            }
            document.getElementById("fileInput").value = null;
          })
          .catch((error) => console.log("error", error));
          
        }else{
          this.cart_store.alert_commomd_message_info("กรุณาใช้ไฟล์ jpg หรือ png เพื่อค้นหาเท่านั้น");
        }
        
      
        
      }

    }
    ,
    addToCartlb(_image_id){
      console.log("_image_id:"+_image_id)
    }
  },
};
</script>

<style scoped>
input[type="checkbox"] {
  display: none;
}

div.gallery:hover {
  cursor: pointer;
  opacity: 0.9;
}

div.gallery_hl:hover {
  cursor: pointer;
  opacity: 0.7;
}

@media screen and (min-width: 851px) and (max-width: 9000px) {
  div.gallery {
    margin: 1px;
    float: left;
    min-height: auto;
    max-height: 193px;
    min-width: auto;
    max-width: 290px;
  }

  div.gallery img {
    position: relative;
    min-height: 100%;
    max-height: 194.66px;
    min-width: 100%;
    padding: 1px;
    max-width: 290px;
  }

  div.gallery_hl {
    margin: 2px;
    float: left;
    min-height: auto;
    max-height: 229px;
    min-width: auto;
    max-width: 346px;
  }

  div.gallery_hl img {
    position: relative;
    min-height: 100%;
    max-height: 231.66px;
    min-width: 100%;
    padding: 0.5px;
    max-width: 346px;
  }
}

@media screen and (min-width: 451px) and (max-width: 850px) {
  div.gallery {
    margin: 1px;
    float: left;
    min-height: auto;
    max-height: 193px;
    min-width: auto;
    max-width: 290px;
  }

  div.gallery img {
    position: relative;
    min-height: 100%;
    max-height: 194.66px;
    min-width: 100%;
    padding: 1px;
    max-width: 290px;
  }

  div.gallery_hl {
    margin: 2px;
    float: left;
    min-height: auto;
    max-height: 225px;
    min-width: auto;
    max-width: 340px;
  }

  div.gallery_hl img {
    position: relative;
    min-height: 100%;
    max-height: 225.96px;
    min-width: 100%;
    padding: 0.5px;
    max-width: 340px;
  }
}

@media (min-width: 300px) and (max-width: 450px) {
  div.gallery {
    width: 49%;
    float: left;
    margin: 1px;
    padding: 1px;
    /* border: 1px solid #ccc; */
    box-shadow: 4px -10px 35px -30px rgba(0,0,0,0.57);
  }

  div.gallery img {
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 2px;
  }

  div.gallery_hl {
    width: 49%;
    float: left;
    margin: 1px;
    /* padding: 1px;
    border: 1px solid #ccc; */
    box-shadow: 4px -10px 35px -30px rgba(0,0,0,0.57);
  }

  div.gallery_hl img {
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 2px;
  }
}

.drop-container {
  position: relative;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 20px;
  border-radius: 10px;
  border: 1px dashed #000000;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}
.drop-container {
  background: #99989846;
}
.drop-container:hover {
  background: #979696ad;
}
/* 
.drop-container:hover .drop-title {
  color: #222;
} */

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

input[type="file"] {
  display: none;
}

.event {
  position: relative;
  width: auto;
  height: auto;
}

.event img {
  height: auto;
  width: auto;
}

.event .btn {
  position: absolute;
  top: 86%;
  left: 82%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #0486d6;
  color: white;
  padding: 3px 3px;
  border: 1px;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
}
.event .btn-free-download {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #0486d6;
  color: white;
  padding: 3px 3px;
  border: 1px;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
}

.event .btn-delete {
  position: absolute;
  top: 86%;
  left: 82%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #ff1c1c;
  color: white;
  padding: 3px 5px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
}

.event .btn-delete-go-to-cart {
  position: absolute;
  top: 10%;
  left: 80%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #ff1c1c;
  color: white;
  padding: 3px 3px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
}

.event_hl {
  position: relative;
  width: auto;
  height: auto;
}

.event_hl img {
  height: auto;
  width: auto;
}
/*
.event .btn:hover {
  background-color: black;
}
.event .btn-delete:hover {
  background-color: black;
}
*/
</style>
    
