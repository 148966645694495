import { defineStore } from "pinia";

import { ref, computed, reactive } from 'vue'

import Swal from 'sweetalert2/dist/sweetalert2.js'

//import 'sweetalert2/src/sweetalert2.scss'
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';

import { router } from '../../router'

import axios from 'axios'

export const useCartStoreWholesale = defineStore('useCartStoreWholesale', () => {
    const cart = ref([])
    const cart_latest = ref([])
    const cart_images = ref([])

    const cart_temp = ref([])

    const mobileNo = ref('')
    const email = ref('')
    const paymentMethod = ref('')

    const _package = ref({})
    const cal_cart = ref({})
    const is_mhao_flag = ref('');
    const event_can_mhao = ref('');
    const mhao_price = ref(0);

    const add_cart = (id, original_file_name, thumbnail_file_name,preview_file_name,photographer_id, event_id,currentLanguage) => {
        const data = {
            id,
            original_file_name,
            thumbnail_file_name,
            preview_file_name,
            photographer_id,
            event_id
        }

        for (let index = 0; index < cart_images.value.length; index++) {
            if(cart_images.value[index].id === data.id){
                cart_images.value[index].atc = "Y";
                break;
            }

        }
       const findId = cart.value.find(e => e.id === data.id)
        if (findId) {
            //alert_add_cart_failed()
        } else {
            cart.value.push(data)
            saveToLocalStorage()
            if(currentLanguage === 'en'){
                alert_add_cart_en(cart.value.length)
            }else{
                alert_add_cart(cart.value.length)
            }
            
        }
    }

    const delete_cart = (id, original_file_name, thumbnail_file_name,photographer_id) => {
        const data = {
            id,
            original_file_name,
            thumbnail_file_name,
            photographer_id
        }

        for (let index = 0; index < cart_images.value.length; index++) {
            if(cart_images.value[index].id === data.id){
                cart_images.value[index].atc = "N";
                break;
            }

        }

        const removeById = (arr, id) => {
            const requiredIndex = arr.findIndex(el => {
                return el.id === String(id);
            });
            if (requiredIndex === -1) {
                return false;
            };
            return !!arr.splice(requiredIndex, 1);
        };

        removeById(cart.value, data.id)
        saveToLocalStorage()
    }

    const saveToLocalStorage = () => {
        localStorage.setItem('cart_wholesale', JSON.stringify(cart.value))
        localStorage.setItem('_event_can_mhao', event_can_mhao.value)
        localStorage.setItem('_mhao_price', mhao_price.value)
        localStorage.setItem('_is_mhao_flag',is_mhao_flag.value);

        localStorage.setItem('cart_temp_wholesale', JSON.stringify(cart_temp.value))

    }


    const loadFromLocalStorage = () => {
        if (localStorage.getItem('cart_wholesale')) {
            //console.log("loadFromLocalStorage")
            cart.value = JSON.parse(localStorage.getItem('cart_wholesale'))
            //console.log("loadFromLocalStoragemm")
            is_mhao_flag.value = localStorage.getItem('_is_mhao_flag');
            event_can_mhao.value = localStorage.getItem('_event_can_mhao');
            mhao_price.value = localStorage.getItem('_mhao_price');
            //console.log("loadFromLocalStoragemm" + is_mhao_flag.value)

            cart_latest.value = JSON.parse(localStorage.getItem('cart_latest_wholesale'))
        }
    }



    const total = computed(() => {
        //console.log("computed cart.js..:")
        //console.log("_package.value.length"+_package.value.length)
        event_can_mhao.value = localStorage.getItem('_event_can_mhao');
        mhao_price.value = localStorage.getItem('_mhao_price');
        //console.log("is_mhao_flag:::::" + is_mhao_flag.value)
        //console.log("event_can_mhao.value" + event_can_mhao.value)
        //console.log("mhao_price.value " + mhao_price.value)



        if (cart.value.length > 0) {
            let cart_count = cart.value.length
            //console.log("cart length: " + cart.value.length)
            let package_price_20 = 0;
            let package_price_10 = 0;
            let package_price_5 = 0;
            let package_price_1 = 0;

            for (let index = 0; index < _package.value.length; index++) {
                const pk = _package.value[index];
                //console.log("pk,"+JSON.stringify(pk))

                if (pk.number_of_photo === 20) {
                    package_price_20 = pk.price

                } else if (pk.number_of_photo === 10) {
                    package_price_10 = pk.price

                } else if (pk.number_of_photo === 5) {

                    package_price_5 = pk.price
                } else if (pk.number_of_photo === 1) {
                    package_price_1 = pk.price
                }
            }

            //  console.log("package_price_20: " + package_price_20)
            //  console.log("package_price_10: " + package_price_10)
            //  console.log("package_price_5: " + package_price_5)
            //  console.log("package_price_1: " + package_price_1)

              console.log("event_can_mhao.value:"+event_can_mhao.value)
              console.log("is_mhao_flag.value:"+is_mhao_flag.value)
              console.log("mhao_price.value:"+mhao_price.value)

            if (event_can_mhao.value === 'YES' && is_mhao_flag.value ==='YES') {

                let cart_amt = Number(mhao_price.value);
                let cart_payment_fee = Number((cart_amt * 0.05).toFixed(2));
                let cart_total_amt = cart_amt.toFixed(2);
                let cart_full_amt = cart_count * package_price_1;
                let cart_discount = cart_full_amt - cart_total_amt;
                let cart_final_payment = (cart_amt + cart_payment_fee).toFixed(2);
                cal_cart.value = {
                    package_count_10: 0,
                    package_count_5: 0,
                    package_count_1: 0,
                    package_price_10: package_price_10,
                    package_price_5: package_price_5,
                    package_price_1: package_price_1,
                    cart_amt: cart_amt,
                    cart_payment_fee: cart_payment_fee.toLocaleString('en'),
                    cart_total_amt: cart_total_amt.toLocaleString('en'),
                    cart_full_amt: cart_amt.toLocaleString('en'),
                    cart_discount: cart_discount.toLocaleString('en'),
                    cart_final_payment: cart_final_payment,
                    cart_full_amt_no_pomo: cart_full_amt.toLocaleString('en')
                }
                return cart_amt;
            } else if(event_can_mhao.value === 'ssYES'){


            
            }else {

                let package_count_20 = 0;
                let package_count_10 = 0;
                let package_count_5 = 0;
                let package_count_1 = 0;

                if (package_price_20 !== 0 && package_price_10 !== 0 && package_price_5 !== 0) {
                    //hava pack 20 10 and 5
                    //console.log("new condition")

                    if (cart_count >= 20) {
                        package_count_20 = Math.trunc(cart_count / 20);
                        const mod_of_20 = cart_count % 20;

                        //console.log("mod_of_20:"+mod_of_20)
                        if (mod_of_20 >= 10) {
                            package_count_10 = Math.trunc(mod_of_20 / 10);
                            const mod_of_10 = mod_of_20 % 10;
                            
                            if (mod_of_10 >= 5) {
                                package_count_5 = Math.trunc(mod_of_10 / 5);
                                const mod_of_5 = mod_of_10 % 5;
    
                                package_count_1 = mod_of_5;
                            } else {
                                package_count_1 = mod_of_10;
                            }
                        }else if (mod_of_20 >= 5) {

                            package_count_5 = Math.trunc(mod_of_20 / 5);
                            const mod_of_5 = mod_of_20 % 5;
    
                            package_count_1 = mod_of_5;
    
    
                        } else {
                            package_count_1 = mod_of_20;
                        }


                    }else if (cart_count >= 10) {

                        //console.log("cart_count >= 10")
                        package_count_10 = Math.trunc(cart_count / 10);
                        const mod_of_10 = cart_count % 10;

                        if (mod_of_10 >= 5) {
                            package_count_5 = Math.trunc(mod_of_10 / 5);
                            const mod_of_5 = mod_of_10 % 5;

                            package_count_1 = mod_of_5;
                        } else {
                            package_count_1 = mod_of_10;
                        }

                    } else if (cart_count >= 5) {
                        //console.log("cart_count >= 5")
                        package_count_5 = Math.trunc(cart_count / 5);
                        const mod_of_5 = cart_count % 5;

                        package_count_1 = mod_of_5;


                    } else {
                        package_count_1 = cart_count;
                    }

                }else if (package_price_10 !== 0 && package_price_5 !== 0) {
                    //hava pack 10 and 5
                    if (cart_count >= 10) {

                        package_count_10 = Math.trunc(cart_count / 10);
                        const mod_of_10 = cart_count % 10;

                        if (mod_of_10 >= 5) {
                            package_count_5 = Math.trunc(mod_of_10 / 5);
                            const mod_of_5 = mod_of_10 % 5;

                            package_count_1 = mod_of_5;
                        } else {
                            package_count_1 = mod_of_10;
                        }

                    } else if (cart_count >= 5) {

                        package_count_5 = Math.trunc(cart_count / 5);
                        const mod_of_5 = cart_count % 5;

                        package_count_1 = mod_of_5;


                    } else {
                        package_count_1 = cart_count;
                    }
                } else if (package_price_10 !== 0) {
                    //have pack 10 only
                    if (cart_count >= 10) {
                        package_count_10 = Math.trunc(cart_count / 10);
                        const mod_of_10 = cart_count % 10;

                        package_count_1 = mod_of_10;
                    } else {
                        package_count_1 = cart_count;
                    }
                } else if (package_price_5 !== 0) {
                    //have pack 5 only
                    if (cart_count >= 5) {

                        package_count_5 = Math.trunc(cart_count / 5);
                        const mod_of_5 = cart_count % 5;

                        package_count_1 = mod_of_5;

                    } else {
                        package_count_1 = cart_count;
                    }

                } else {
                    //have pack 1 pic x bath
                    package_count_1 = cart_count;
                }
                //console.log("package_count_20: " + package_count_20)
                //console.log("package_count_10: " + package_count_10)
                //console.log("package_count_5: " + package_count_5)
                //console.log("package_count_1: " + package_count_1)
                let cart_amt = (package_count_20 * package_price_20) + (package_count_10 * package_price_10) +
                    (package_count_5 * package_price_5) +
                    (package_count_1 * package_price_1)
                //console.log("cart_amt: " + cart_amt)
                if(event_can_mhao.value === 'YES' && mhao_price.value !== 0){
                    let cart_amt_temp = Number(mhao_price.value);
                    if(cart_amt > cart_amt_temp){
                        cart_amt = cart_amt_temp//user mhao amt
                    }
                }

                let cart_full_amt = cart_count * package_price_1;
                let cart_total_amt = cart_amt.toFixed(2);
                let cart_discount = cart_full_amt - cart_total_amt;

                let cart_payment_fee = Number((cart_amt * 0.05).toFixed(2));

                let cart_final_payment = (cart_amt + cart_payment_fee).toFixed(2);




                cal_cart.value = {
                    package_count_20: package_count_20,
                    package_count_10: package_count_10,
                    package_count_5: package_count_5,
                    package_count_1: package_count_1,
                    package_price_10: package_price_10,
                    package_price_5: package_price_5,
                    package_price_1: package_price_10,
                    cart_amt: cart_amt,
                    cart_payment_fee: cart_payment_fee.toLocaleString('en'),
                    cart_total_amt: cart_total_amt.toLocaleString('en'),
                    cart_full_amt: cart_full_amt.toLocaleString('en'),
                    cart_discount: cart_discount.toLocaleString('en'),
                    cart_final_payment: cart_final_payment,
                    cart_full_amt_no_pomo: cart_full_amt.toLocaleString('en')
                }

                 //console.log("cal_cart"+JSON.stringify(cal_cart))
                return cart_amt;
            }
        } else {

        }

        return cart.value.reduce((sum, prd) => sum + 60 * 1, 0)
    })

    const alert_add_cart = (imageCount) => {
        Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'เพิ่มรูปเรียบร้อยแล้วจำนวน ' + imageCount + ' ใบ',
            //html: '<a href="/cart"><button type="button" class="btn btn-success">ชำระเงิน</button></a>',
            showCloseButton: false,
            showConfirmButton: false,
            timer: 1000
        })
        
    }

    const alert_add_cart_en = (imageCount) => {
        Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'Number of photos added ' + imageCount + '  items',
            //html: '<a href="/cart"><button type="button" class="btn btn-success">ชำระเงิน</button></a>',
            showCloseButton: false,
            showConfirmButton: false,
            timer: 1000
        })
        
    }

    const alert_add_cart_failed = () => {
        Swal.fire({
            position: 'top-center',
            icon: 'error',
            title: 'คุณได้เพิ่มรูปนี้ไปแล้ว',
            showConfirmButton: false,
            timer: 1500
        })
    }

    const clear_cart_images = () => {
        cart_images.value = []
    }

    const clear_cart_latest = () => {
        cart_latest.value = []
    }

    const remove_cart = (i) => {
        cart.value.splice(i, 1)
        saveToLocalStorage()
    }

    const clear_cart = (is_event_can_mhao) => {
        //console.log("is_event_can_mhao:"+is_event_can_mhao)
        if(is_event_can_mhao === 'Y'){
            cart.value = []

            is_mhao_flag.value = "NO"
            event_can_mhao.value = 'YES'
            mhao_price.value = 0
            saveToLocalStorage()

        }
        
    }

    const clear_all_cart_in_cart_page = () => {

            cart.value = []
            cart_latest.value = []
            cart_temp.value = []
            clear_mhao('N','N',0,'N')
            saveToLocalStorage()

            localStorage.setItem('cart_latest_wholesale', JSON.stringify(cart_latest.value))
        
    }


    const addToCartALLImage =  async (_imageId, _is_mhao, _mhao_price) => {

        cart.value = [];
        cart.value = _imageId;
        if (_is_mhao === 'Y') {
            is_mhao_flag.value = "YES"
            event_can_mhao.value = "YES"
            mhao_price.value = _mhao_price
        }else{
            is_mhao_flag.value = "NO"
            event_can_mhao.value = "NO"
            mhao_price.value = 0
        }

        saveToLocalStorage()

        window.location.href = '/cart-wholesale';
        //this.$router.push('/cart')
    }

    const removeImageInCart = (imageId) => {

        const removeById = (arr, id) => {
            const requiredIndex = arr.findIndex(el => {
                return el.id === String(id);
            });
            if (requiredIndex === -1) {
                return false;
            };
            return !!arr.splice(requiredIndex, 1);
        };

        removeById(cart.value, imageId)
        saveToLocalStorage()

    }


    const qrPayment = (amount, mobile, email, payment_mothod) => {

        //console.log(JSON.stringify(mobile), email, payment_mothod)

    }

    const order_creation = async (data) => {
        if(data.payment_gateway=== 'OPN'){
            let result = await axios.post('/v3/order/create', data)
                .then((response) => {
                    return response.data;
                }).catch((err) => {
                    console.log(err)
            })
            return result;
        }else {
            if(data.payment_channel === 'PP'){
                let result = await axios.post('/v1/order/create', data)
                .then((response) => {
                    return response.data;
                }).catch((err) => {
                    console.log(err)
                })
                return result;
            }else{
                //other still call opn
                let result = await axios.post('/v3/order/create', data)
                .then((response) => {
                    return response.data;
                }).catch((err) => {
                    console.log(err)
                })
                return result;
            }
        }
        
    }

    const addPackage = (__package) => {
        _package.value = __package;
    }

    const alert_create_order = async (order_id) => {
        await Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'สร้างรายการคำสั่งซื้อ ' + order_id + ' เรียบร้อย',
            html: '<a href="/download/' + order_id + '"><button type="button" class="btn btn-success">ดูคำสั่งซื้อ</button></a>',
            showCloseButton: true,
            showConfirmButton: false
        })
        return true;
    }
    const alert_create_order_en = async (order_id) => {
        await Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'Create crder [' + order_id +'] completed',
            html: '<a href="/download/' + order_id + '"><button type="button" class="btn btn-success">Payment</button></a>',
            showCloseButton: true,
            showConfirmButton: false
        })
        return true;
    }
    const alert_create_order_failed = () => {
        Swal.fire({
            position: 'top-center',
            icon: 'error',
            title: 'พบข้อผิดพลาดระหว่างการสร้างรายการคำสั่งซื้อ กรุณาลองอีกครั้งหรือติดต่อ Line @running.in.th',
            showConfirmButton: true,
            timer: 7000
        })
    }

    const alert_upload_file_more_than_5mb = () => {
        Swal.fire({
            position: 'top-center',
            icon: 'warning',
            title: 'กรุณา upload file ที่มีขนาด น้อยกว่า 5MB',
            showConfirmButton: true,
            timer: 5000
        })
    }

    const alert_commomd_message_info = (message) => {
        Swal.fire({
            position: 'top-center',
            icon: 'error',
            title: message,
            showConfirmButton: true,
            timer: 7000
        })
    }

    const clear_mhao = (_is_mhao, _event_can_mhao, _mhao_price,_is_from_screen) => {

        
        if(_event_can_mhao === 'Y'){
            if(_is_mhao === 'Y'){
                is_mhao_flag.value = "YES"
                event_can_mhao.value = 'YES'
                mhao_price.value = _mhao_price
                saveToLocalStorage()
    
            }else{

                if(_is_from_screen === 'Y'){

                    if(cart_temp.value.length === 0){
                        
                        alert("ไม่สามารถทำรายการได้ เนื่องจากเราต้องการให้นักวิ่งซื้อภาพในราคาย่อมเยา :) โดยท่านสามารถลบภาพที่ไม่ต้องการเหมา ออกจากตะกร้าได้เลย หรือลองค้นหา และเลือกภาพใหม่อีกครั้ง")
                        
                    }else{
                        if(confirm("ภาพที่เลือกล่าสุดจำนวน "+cart_temp.value.length+" ใบ จะถูกเพิ่มเข้ามาในตะกร้าอัตโนมัติ ")){
                            is_mhao_flag.value = "NO"
                            event_can_mhao.value = 'YES'
                            mhao_price.value = _mhao_price
                            
                            cart.value = cart_temp.value;
                            cart_temp.value = []
            
                            saveToLocalStorage()
            
                        }
                    }

                    
                }

                
                
            }

        }else{
            is_mhao_flag.value = "NO"
            event_can_mhao.value = 'NO'
            mhao_price.value = 0
            saveToLocalStorage()
        }
    }

    const select_pro_mhao = (_is_mhao, _event_can_mhao, _mhao_price) => {
        
        if(confirm("ภาพที่ค้นหาล่าสุดจำนวน "+cart_latest.value.length+" ใบ จะถูกเพิ่มเข้ามาในตะกร้าอัตโนมัติ ")){
            if(_event_can_mhao === 'Y'){
                if(_is_mhao === 'Y'){
                    event_can_mhao.value = "YES"
                    mhao_price.value = _mhao_price
                    is_mhao_flag.value = "YES"
    
                    //automhao
                    cart_temp.value = cart.value;
                    cart.value = cart_latest.value

                    saveToLocalStorage()
                }
            }
        }
    }

    const alert_policy_consent = async () => {
        let result = await Swal.fire({
            title: 'ข้อตกลงการใช้งานระบบ (Terms of Use)',
            html: "ข้าพเจ้ายอมรับข้อตกลงการใช้งาน และอ่านทำความเข้าใจนโยบายของระบบ <a href='/terms-of-use' target=_blank>ข้อตกลงและ นโยบายในการใช้งานระบบ</a>",
            
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#7991a8',
            confirmButtonText: 'ยอมรับ',
            cancelButtonText: 'ไม่ยอมรับ'
        }).then((result) => {
            if (result.isConfirmed) {   
                return true;
            }else{
                return false;
            }
        });
            return result;
    }

    const alert_policy_consent_en = async () => {
        let result = await Swal.fire({
            title: 'Terms of Use',
            html: "I accept the terms of use. and read and understand the system policies <a href='/terms-of-use' target=_blank>Terms and policies for using the system</a>",
            
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#7991a8',
            confirmButtonText: 'Accept',
            cancelButtonText: 'Deny'
        }).then((result) => {
            if (result.isConfirmed) {   
                return true;
            }else{
                return false;
            }
        });
            return result;
    }



    return {
        cart, add_cart, loadFromLocalStorage, remove_cart, clear_cart,clear_all_cart_in_cart_page, total,
        addToCartALLImage, removeImageInCart, qrPayment, mobileNo, email, paymentMethod,
        order_creation, total, _package, addPackage, cal_cart, alert_create_order_failed,
        alert_create_order,alert_create_order_en, alert_commomd_message_info, is_mhao_flag, clear_mhao, select_pro_mhao,alert_policy_consent,alert_policy_consent_en,
        cart_images,clear_cart_images,delete_cart,alert_upload_file_more_than_5mb,
        cart_temp,cart_latest,clear_cart_latest

    }
});