<template>
  <Breadcrumbs title="" main="Pages" />
  <div class="container-fluid">
    <div class="faq-wrap">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <form @submit.prevent="findOrder">
            <div class="card">
              <div class="card-body">
                <p>&nbsp;</p>
                <div class="media">
                  <vue-feather class="m-r-30" type="search"></vue-feather>
                  <div class="media-body">
                 
                    <div class="row">
                      
                        <label for="moblieNo" >
                        </label>
                        <div class="col-sm-12">
                        <p><b>{{this.currentLanguage ==='en'?'Find order by mobile no or receipt':'ค้นหาคำสั่งซื้อ โดยหมายเลขโทรศัพท์ หรือ สลิปโอนเงิน'}}</b></p>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="filter_type" checked required type="radio"
                          name="inlineRadioOptions" id="inlineRadio1" value="mobile_no">
                        <label class="form-check-label" for="inlineRadio1">
                          {{this.currentLanguage ==='en'?'Mobile No':'หมายเลขโทรศัพท์'}}
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="filter_type" type="radio" required name="inlineRadioOptions"
                          id="inlineRadio2" value="receipt">
                        <label class="form-check-label" for="inlineRadio2">
                          {{this.currentLanguage ==='en'?'Receipt':'สลิปโอนเงิน'}}
                        </label>
                      </div>
                    </div>
                        <input required
                          :maxlength="this.filter_type==='mobile_no'?15:4"
                          :minlength="this.filter_type==='mobile_no'?10:4"
                          type="text"
                          class="form-control"  
                          id="txt_search" 
                          :placeholder="this.filter_type==='mobile_no'?this.currentLanguage ==='en'?'Mobile No':'หมายเลขโทรศัพท์ ที่ใช้ในการสั่งซื้อ':this.currentLanguage ==='en'?'receipt ref last 4 digit':'ตัวอักษร 4 ตัวท้ายของสลิปโอนเงิน'"
                          v-model="txt_search"
                        />
                     
                      <b v-if="this.filter_type ==='receipt'"><br>&nbsp;&nbsp;&nbsp;***ตัวอย่างเลยท้ายเสร็จ 4 ตัวท้าย</b>
                      <div v-if="this.filter_type ==='receipt'" class="d-flex justify-content-left">
                        <img width="400" src="../../assets/images/running/example-slip.jpg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">&nbsp;</div>
                <div class="row">
                  <div class="d-flex justify-content-center">
                    <button
                      type="submit"
                      style="cursor: pointer"
                      class="btn btn-primary btn-lg"
                    >
                    <vue-feather type="loader" size="20" animation="spin" v-if="this.is_searching ==='true'"></vue-feather> {{this.currentLanguage ==='en'?"Search":"ค้นหา"}}
                    </button>
                    &nbsp;
                    <button
                      style="cursor: pointer"
                      @click.prevent="clear_result"
                      type="reset"
                      class="btn btn-secondary btn-lg"
                    >
                      {{this.currentLanguage ==='en'?"Reset":"รีเซ็ต"}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-sm-1"></div>
      </div>

      <div class="row" v-if="this.start_search === 'true'">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <div class="card">
            <div class="card-body">
              <div v-if="this.is_searching === 'true'">
                <div class="d-flex justify-content-center">
                  <img width="60" src="../../assets/images/running_loading.svg" />
                </div>
              </div>

              <div
                v-if="!this.resultList.length && this.is_searching === 'false'"
              >
                <table class="table table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">{{this.currentLanguage ==='en'?"Order date":"วันที่สั่งซื้อ"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Order":"คำสั่งซื้อ"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Event":"กิจกรรม"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Status":"สถานะ"}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="4" style="text-align: center">
                        {{this.currentLanguage ==='en'?"order not found":"ไม่พบข้อมูลคำสั่งซื้อ"}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div v-if="this.resultList.length" class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">{{this.currentLanguage ==='en'?"Order date":"วันที่สั่งซื้อ"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Order":"คำสั่งซื้อ"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Event":"กิจกรรม"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Status":"สถานะ"}}</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(src, index) in this.resultList" :key="index">
                      <td>{{ src.create_date }}</td>
                      <td >
                        <a
                          target="_blank"
                          :href="
                            '/download/' + src.order_id
                          "
                          ><b><u><vue-feather size="15" type="image"></vue-feather>{{this.currentLanguage ==='en'?"Open gallery":"เปิด ลิงค์สำหรับโหลดรูปภาพ"}}</u></b></a
                        >
                      </td>
                      <td>{{ src.title }}</td>
                      <td v-if="src.status === 'INITIAL'">
                        <font style="color: red;">{{this.currentLanguage ==='en'?"Waiting for payment":"รอชำระเงิน"}}</font>
                      </td>
                      <td v-if="src.status === 'PAID'">
                        <font style="color: green;">{{this.currentLanguage ==='en'?"Completed":"สำเร็จ"}}</font>
                      </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-1"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { useHead } from '@vueuse/head'
import {computed, reactive } from 'vue'
export default {
  data() {
    return {
      email: "",
      mobile: "",
      resultList: {},
      status: "",
      is_searching: "false",
      start_search: "false",
      currentLanguage: 'th',
      filter_type: "mobile_no",
      txt_search: ""
    };
  },
  setup() {
    useHead({
      title: computed(() => `ค้นหาคำสั่งซื้อ ค้นหารูปความประทับใจด้วยใบหน้า | RUNNING.IN.TH`),
      meta: [
        {
          name: `description`,
          content: computed(() => `ค้นหาคำสั่งซื้อ ค้นหารูปวิ่งสุดประทับใจด้วยใบหน้า ด้วยใบหน้า ค้นง่าย จ่ายสะดวก โหลดรูปต้นฉบับ ความละเอียดสูงได้ทันที`),
        },
        {
          name: `keywords`,
          content: computed(() => `ค้นหาคำสั่งซื้อ,ค้นหารูปวิ่ง, หารูปวิ่ง,งานวิ่ง,running,runninghub,facesearch`),
        }
        ],
     
    })
  },
  async created(){
    this.currentLanguage = localStorage.getItem('currentLanguage');
  },
  methods: {
    async findOrder() {

      this.start_search = "true";
      this.resultList = {};
      this.is_searching = "true";

      
      const data = {
        tk: process.env.VUE_APP_FN_TO_BK_SECRET_KEY,
        filter_type: this.filter_type==='receipt'?'payment_ref':'mobile',
        txt_search: this.txt_search,
      };
      let result = await axios.post("/v1/order/find-order", data);
      this.status = result.data.status;
      if (result.data.status === "success") {
        this.resultList = result.data.body;
      }
      this.is_searching = "false";
    },
    async clear_result() {
      this.start_search = "false";
      this.resultList = {};
      this.filter_type = "mobile_no",
      this.txt_search = ""
    },
  },
};
</script>